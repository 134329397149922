.contact-page {
	background-color: $darker;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	padding: 20px 5px 30px 5px;
	display: flex;
    align-items: center;

	@media (min-width: $breakpoint-desktop) {
		padding: 20px 60px;
	}

	> .container-fluid {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.contact-table {
		&__row {
			&:first-child {
				@media (min-width: $breakpoint-desktop) {
					border-top: none;
				}
			}
		}
	}

	&__title {
		width: 100%;
		border-top: 1px solid rgba($light, 0.5);
		border-bottom: 1px solid rgba($light, 0.5);
		margin-top: 100px;
		padding: 14px 0;
		text-transform: uppercase;
		margin-bottom: 60px;

		@media (min-width: $breakpoint-desktop) {
			padding: 25px 0;
			margin-bottom: 0;
		}

		h1 {
			font-size: 27px;
			letter-spacing: 0.92px;
			line-height: 33px;
			color: $light;

			@media (min-width: $breakpoint-desktop) {
				font-size: 86px;
				letter-spacing: 4.3px;
				line-height: 105px;
			}
		}
	}

	.social-container {
		@media (min-width: $breakpoint-desktop) {
			margin-left: calc(-100% - 20px);
    		width: calc(200% + 20px);
		}
	}
}