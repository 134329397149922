.contact-table {
	width: 100%;

	&__row {
		border-top: 1px solid rgba($light, 0.5);
		display: flex;
		flex-direction: row;
		padding: 25px 0 40px 0;

		@media (min-width: $breakpoint-desktop) {
			padding: 30px 0 50px 0;
		}
	}

	&__title {
		min-width: 25%;
		font-size: 14px;
		text-transform: uppercase;
		letter-spacing: 0.7px;
		color: $light;
		transform: translate(0, 4px);

		@media (min-width: $breakpoint-desktop) {
			min-width: 40%;
			font-size: 33px;
			line-height: 40px;
			letter-spacing: 1.65px;
			transform: none;
		}
	}

	&__value {
		min-width: 75%;
		font-size: 13px;
		letter-spacing: 0.65px;
		line-height: 22px;
		color: $light;
		font-weight: normal;
		-webkit-font-smoothing: antialiased;

		@media (min-width: $breakpoint-desktop) {
			min-width: 60%;
			font-size: 16px;
			letter-spacing: 0.8px;
			line-height: 22px;
		}

		a {
			color: $light;
			text-decoration: none;
			font-weight: normal;
			-webkit-font-smoothing: antialiased;
		}

		.button {
			margin-top: 20px;
			display: inline-block;
		}

		&--has-button {
			@media (min-width: $breakpoint-desktop) {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
			}

			.button {
				@media (min-width: $breakpoint-desktop) {
					margin-top: 0;
				}
			}
		}
	}
}