.home-scroll {
	$block: &;

	background-color: $dark;

	@media (min-width: $breakpoint-desktop) {
		width: 100%;
		height: 100%;
	}

	.scroll-horizontal  {
		scroll-behavior: smooth;

		@media (max-width: $breakpoint-desktop) {
			height: auto !important;
			width: auto !important;
			overflow: visible !important;
		}

		> div {
			@media (max-width: $breakpoint-desktop) {
				transform: none !important;
				max-width: 100% !important;
				opacity: 1;
			}

			@media (max-width: $breakpoint-desktop) {
				height: auto !important;
				width: auto !important;
				overflow: visible;
				position: relative !important;
				display: block !important;
			}
		}
	}

	&__lock-layer {
		width: 100%;
		height: 100%;
		position: fixed;
		cursor: wait;
		z-index: 9999;
		top: 0;
		left: 0;
	}

	&__intro {
		background-color: $dark;
		width: 100vw;
		height:  70vh;
		min-height: 450px;
		position: relative;
		transition: width 1.9s ease-in-out;
		z-index: 10;

		@media (min-width: $breakpoint-tablet) {
			height:  100vh;
		}
	}

	&__intro-animation {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 80px 20px 20px;

		@media (min-width: $breakpoint-desktop) {
			padding: 60px 80px;
		}

		&--active {
			#{$block} {
				&__slogan {
					opacity: 1;
				}

				&__temp-text {
					opacity: 1;
				}
			}
		}
	}

	&__text-logo {
		width: 241px;
		height: 10px;
		opacity: 0;
		transition: opacity 2s;
		position: absolute;
		top: 0;
		left: 0;

		&-container {
			position: relative;

			&--hidden {
				@media (min-width: $breakpoint-tablet) {
					opacity: 0;
					transition: opacity 0.3s;
					pointer-events: none;
				}
			}
		}
	}

	
	&__slogan {
		width: 100%;
		height: auto;
		margin-left: -5px;
		opacity: 0;
		transition: opacity 2s 1s;
		margin-bottom: auto;
    	margin-top: 30px;

		@media (min-width: 400px) {
			width: 315px;
			height: 108px;
		}

		@media (min-width: $breakpoint-tablet) {
			width: 587px;
			height: 224px;
			margin-top: auto;
		}
	}

	&__temp-text {
		color: $light;
		width: 100%;
		margin-left: -5px;
		opacity: 0;
		transition: opacity 2s 1s;
		margin-bottom: auto;
    margin-top: 30px;
    font-size: 16px;
    letter-spacing: 0.8px;
    line-height: 24px;

		@media (min-width: 400px) {
			width: 300px;
		}

		@media (min-width: $breakpoint-tablet) {
			width: 450px;
			margin-top: -95px;
		}

		@media (min-width: $breakpoint-tablet) and (max-height: 750px) {
			display: none;
		}
	}

	&__logo {
		opacity: 0;
		width: calc(100vw - 60px);
		overflow:  hidden;
		position: relative;
		z-index: 1;
		transition: opacity 0.3s 0.5s;
		animation: movelogo 2s 2.5s ease-in-out forwards;

		@media (min-width: $breakpoint-tablet) {
			width: 100vw;
			animation: movelogobig 2s 2.5s ease-in-out forwards;
		}

		@keyframes movelogo {
			0% {
				transform: none;
			}

			100% {
				transform: translate(-121px, 0);
			}
		}

		@keyframes movelogobig {
			0% {
				transform: none;
			}

			100% {
				transform: translate(-243px, 0);
			}
		}

		@media (min-width: $breakpoint-tablet) {
			/*overflow: visible;*/
			width:  auto;
		}

		&:before {
			content: " ";
			position: absolute;
			top: -10px;
			left: -200%;
			display: block;
			width: 500%;
			height: 20px;
			background-color: $dark;
			z-index: 1;
			backface-visibility: hidden;
		}

		&:after {
			content: " ";
			position: absolute;
			bottom: -10px;
			left: -200%;
			display: block;
			width: 500%;
			height: 20px;
			background-color: $dark;
			z-index: 1;
			backface-visibility: hidden;
		}

		&-svg {
			overflow: visible;
			width: 405px;
			height: 80px;
			min-height: 80px;
			max-height: 80px;

			@media (min-width: $breakpoint-tablet) {
				width: 810px;
				height: 153px;
				min-height: 153px;
				max-height: 153px;
			}

			@media (min-width: $breakpoint-desktop) {
				width: 810px;
				height: 161px;
				min-height: 161px;
				max-height: 161px;
			}

			polygon {
				fill: $darker;
			}

			#left-1,
			#left-2,
			#left-3,
			#left-4,
			#right-1,
			#right-2,
			#right-3,
			#right-4 {
				transform-origin: 50% 50%;
			}


			@keyframes left1 {
				0% {
					transform: rotate(0deg) translate(66.375px, 0px);
				}

				55% {
					fill: $darker;
				}

				50% {
					transform: translate(-103.545px, 0px);
				}

				99% {
					fill: $light;
				}

				100% {
					transform: none;
					fill: $light;
				}
			}

			@keyframes left1big {
				0% {
					transform: rotate(0deg) translate(132.75px, 0px);
				}

				55% {
					fill: $darker;
				}

				50% {
					transform: translate(-207.09px, 0px);
				}

				99% {
					fill: $light;
				}

				100% {
					transform: none;
					fill: $light;
				}
			}

			@keyframes right1 {
				0% {
					transform: rotate(0deg) translate(-66.375px, 0px);
				}

				55% {
					fill: $darker;
				}

				50% {
					transform: translate(103.545px, 0px);
				}

				99% {
					fill: $light;
				}

				100% {
					transform: none;
					fill: $light;
				}
			}

			@keyframes right1big {
				0% {
					transform: rotate(0deg) translate(-132.75px, 0px);
				}

				55% {
					fill: $darker;
				}

				50% {
					transform: translate(207.09px, 0px);
				}

				99% {
					fill: $light;
				}

				100% {
					transform: none;
					fill: $light;
				}
			}

			@keyframes left2 {
				0% {
					transform: rotate(3.625deg) translate(48.75px, 0px);
				}

				55% {
					fill: $darker;
				}

				50% {
					transform: translate(-75.6px, 0px);
				}

				99% {
					fill: $light;
				}

				100% {
					transform: none;
					fill: $light;
				}
			}

			@keyframes left2big {
				0% {
					transform: rotate(7.25deg) translate(97.5px, 0px);
				}

				55% {
					fill: $darker;
				}

				50% {
					transform: translate(-151.2px, 0px);
				}

				99% {
					fill: $light;
				}

				100% {
					transform: none;
					fill: $light;
				}
			}

			@keyframes right2 {
				0% {
					transform: rotate(-3.625deg) translate(-48.75px, 0px);
				}

				55% {
					fill: $darker;
				}
				
				50% {
					transform: translate(75.6px, 0px);
				}

				99% {
					fill: $light;
				}

				100% {
					transform: none;
					fill: $light;
				}
			}

			@keyframes right2big {
				0% {
					transform: rotate(-7.25deg) translate(-97.5px, 0px);
				}

				55% {
					fill: $darker;
				}
				
				50% {
					transform: translate(151.2px, 0px);
				}

				99% {
					fill: $light;
				}

				100% {
					transform: none;
					fill: $light;
				}
			}

			@keyframes left3 {
				0% {
					transform: rotate(7.25deg) translate(31.25px, 0px);
				}

				55% {
					fill: $darker;
				}

				50% {
					transform: translate(-48.75px, 0px);
				}

				99% {
					fill: $light;
				}

				100% {
					transform: none;
					fill: $light;
				}
			}

			@keyframes left3big {
				0% {
					transform: rotate(14.5deg) translate(62.5px, 0px);
				}

				55% {
					fill: $darker;
				}

				50% {
					transform: translate(-97.5px, 0px);
				}

				99% {
					fill: $light;
				}

				100% {
					transform: none;
					fill: $light;
				}
			}

			@keyframes right3 {
				0% {
					transform: rotate(-7.25deg) translate(-31.25px, 0px);
				}

				55% {
					fill: $darker;
				}

				50% {
					transform: translate(48.75px, 0px);
				}

				99% {
					fill: $light;
				}

				100% {
					transform: none;
					fill: $light;
				}
			}

			@keyframes right3big {
				0% {
					transform: rotate(-14.5deg) translate(-62.5px, 0px);
				}

				55% {
					fill: $darker;
				}

				50% {
					transform: translate(97.5px, 0px);
				}

				99% {
					fill: $light;
				}

				100% {
					transform: none;
					fill: $light;
				}
			}

			@keyframes left4 {
				0% {
					transform: rotate(11deg) translate(14px, 0px);
				}

				55% {
					fill: $darker;
				}

				50% {
					transform: none;
				}

				99% {
					fill: $light;
				}

				100% {
					transform: none;
					fill: $light;
				}
			}

			@keyframes left4big {
				0% {
					transform: rotate(22deg) translate(28px, 0px);
				}

				55% {
					fill: $darker;
				}

				50% {
					transform: none;
				}

				99% {
					fill: $light;
				}

				100% {
					transform: none;
					fill: $light;
				}
			}

			@keyframes right4 {
				0% {
					transform: rotate(-11deg) translate(-14px, 0px);
				}

				55% {
					fill: $darker;
				}

				50% {
					transform: none;
				}

				99% {
					fill: $light;
				}

				100% {
					transform: none;
					fill: $light;
				}
			}

			@keyframes right4big {
				0% {
					transform: rotate(-22deg) translate(-28px, 0px);
				}

				55% {
					fill: $darker;
				}

				50% {
					transform: none;
				}

				99% {
					fill: $light;
				}

				100% {
					transform: none;
					fill: $light;
				}
			}

			#left-1 {
				/*transform: rotate(7deg) translate(0, 12px);*/
				animation: left1 4s 0.5s ease-in-out forwards;

				@media (min-width: $breakpoint-tablet) {
					animation: left1big 4s 0.5s ease-in-out forwards;
				}
			}

			#right-1 {
				/*transform: rotate(-7deg) translate(0, 12px);*/
				animation: right1 4s 0.5s ease-in-out forwards;

				@media (min-width: $breakpoint-tablet) {
					animation: right1big 4s 0.5s ease-in-out forwards;
				}
			}

			#left-2 {
				/*transform: rotate(7deg) translate(0, 12px);*/
				animation: left2 4s 0.5s ease-in-out forwards;

				@media (min-width: $breakpoint-tablet) {
					animation: left2big 4s 0.5s ease-in-out forwards;
				}
			}

			#right-2 {
				/*transform: rotate(-7deg) translate(0, 12px);*/
				animation: right2 4s 0.5s ease-in-out forwards;

				@media (min-width: $breakpoint-tablet) {
					animation: right2big 4s 0.5s ease-in-out forwards;
				}
			}

			#left-3 {
				/*transform: rotate(14deg) translate(0, 16px);*/
				animation: left3 4s 0.5s ease-in-out forwards;

				@media (min-width: $breakpoint-tablet) {
					animation: left3big 4s 0.5s ease-in-out forwards;
				}
			}

			#right-3 {
				/*transform: rotate(-14deg) translate(0, 16px);*/
				animation: right3 4s 0.5s ease-in-out forwards;

				@media (min-width: $breakpoint-tablet) {
					animation: right3big 4s 0.5s ease-in-out forwards;
				}
			}

			#left-4 {
				/*transform: rotate(22deg) translate(0, 14px);*/
				animation: left4 4s 0.5s ease-in-out forwards;

				@media (min-width: $breakpoint-tablet) {
					animation: left4big 4s 0.5s ease-in-out forwards;
				}
			}

			#right-4 {
				/*transform: rotate(-22deg) translate(0, 16px);*/
				animation: right4 4s 0.5s ease-in-out forwards;

				@media (min-width: $breakpoint-tablet) {
					animation: right4big 4s 0.5s ease-in-out forwards;
				}
			}
		}
	}

	&__project {
		background-color: $lighter;
		position: relative;
		backface-visibility: hidden;

		@media (min-width: $breakpoint-desktop) {
			width: 100vw;
			height: 100vh;
		}

		&-container {
			display: flex;
			flex-direction: column;
			padding: 20px 20px;
			background-color: $dark;

			@media (min-width: $breakpoint-desktop) {
				height: 100%;
				flex-direction: row;
				padding: 0;
				background-color: rgba(0, 0, 0, 0);
			}
		}

		&-inner-container {
			z-index: 25;
		}

		&-background-layer {
			background-color: $lighter;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			opacity:  0;
			transition:  opacity 1s;
			backface-visibility: hidden;
			pointer-events: none;
			display: none;

			@media (min-width: $breakpoint-desktop) {
				pointer-events: auto;
				display: block;
			}

			&--show {
				opacity:  1;
			}
		}

		&-image {
			background-color: $dark;
			position: relative;
			overflow: hidden;
			height: 70vh;

			@media (min-width: $breakpoint-desktop) {
				min-width: 60%;
				height: 100%;
			}

			figure {
				position: absolute;
				max-width: 100%;
				max-height: 100%;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-size: cover;
				background-position: 50% 50%;
				will-change: transform, max-height, max-width;
				transform: scale(1) translate3d(0, 0, 0);
				transition: width 1s linear, max-height 2s 0s linear, max-width 2s 0s linear, transform 0.2s linear; // in js as well
				transform-origin: 50% 50%;
				background-color: darken($dark, 5%);
				overflow: hidden;
				z-index: 1;

				@media (max-width: $breakpoint-desktop) {
					transform: none !important;
					max-width: 100% !important;
				}
			}

			&--clickable {
				display: none;

				@media (min-width: $breakpoint-tablet) {
					display: block;
					z-index: 10;
					position: absolute;
					overflow: hidden;
					height: 70vh;
					min-width: 60%;
					height: 100%;
				}
			}
		}

		&-content {
			background-color: $dark;
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: baseline;
			justify-content: center;
			position: relative;
			z-index: 2;
			transform: translateX(-1px);
			/*transition:  opacity 0s;

			&.color-light {
				background-color: $light;
			}*/
		}

		&-inner-container {
			width: 100%;
			display: flex;
			flex-direction: column;

			@media (min-width: $breakpoint-desktop) {
				width: auto;
			}
		}

		&-title {
			font-size: 50px;
			letter-spacing: 1.5px;
			line-height: 62px;
			color: $light;
			width: 100%;
			text-align: left;
			transition: transform 0.05s linear, color 1s;
			will-change: transform;
			transition: transform 0.1s;
			margin-top: 10px;

			@media (max-width: $breakpoint-desktop) {
				transform: none !important;
				max-width: 100% !important;
				opacity: 1;
			}

			@media (min-width: $breakpoint-desktop) {
				font-size: 100px;
				letter-spacing: 0;
				line-height: 122px;
				margin-left: -80px;
				width: auto;
				text-align: right;
				margin-top: 0;
			}

			&--left {
				text-align: left;

				@media (min-width: $breakpoint-tablet) {
					margin-left: 0;
				}
			}
		}

		&-tagline {
			font-size: 18px;
			letter-spacing: 0.83px;
			line-height: 27px;
			color: $light;
			align-self: flex-start;
			transition: transform 0.05s linear, color 1s;
			font-family: $extra-font;
			will-change: transform;

			@media (max-width: $breakpoint-desktop) {
				transform: none !important;
				max-width: 100% !important;
				opacity: 1;
			}

			@media (min-width: $breakpoint-desktop) {
				font-size: 28px;
				letter-spacing: 1.44px;
				line-height: 43px;
				align-self: flex-end;
			}
		}

		&-cta {
			align-self: flex-end;
			transition: background-color 0.3s, transform 0.05s linear, opacity 0.5s;
			margin-top: 60px;
			will-change: transform;
			transition: transform 0.1s;
			display: none;

			@media (max-width: $breakpoint-desktop) {
				transform: none !important;
				max-width: 100% !important;
				opacity: 1;
			}

			@media (min-width: $breakpoint-desktop) {
				display: block;
			}
		}

		&--active {
			#{$block} {
				&__project {
					&-title,
					&-tagline {
						color: $dark;
						transition: transform 2s 0s ease-in-out, color 2s 0s ease-in-out;
					}

					&-content {
						z-index: 40;
					}

					&-cta {
						opacity: 0;
					}

					&-image {
						figure {
							max-height: 70vh;
							max-width: 100%;
							transition: height 2s 0s ease-in-out, max-height 2s 0s ease-in-out, width 2s 0s ease-in-out, max-width 2s 0s ease-in-out, transform 2s 0s ease-in-out !important;
							z-index:  30;
						}
					}
				}
			}
		}

		/*&--current {
			#{$block} {
				&__project {
					&-image {
						background-color: #f0f;
					}
				}
			}
		}

		&--prev {
			#{$block} {
				&__project {
					&-image {
						background-color: #ff0;
					}
				}
			}
		}*/

		&:before {
			@media (min-width: $breakpoint-desktop) {
				content: " ";
				background-color: $darker;
				width: 30px;
				height: 100%;
				position: absolute;
				top: 0;
				right: -15px;
			}
		}

		&--last {
			margin-bottom: 25px;

			@media (min-width: $breakpoint-desktop) {
				margin-bottom: 0;
			}
		}
	}

	&__triangle {
		display: none;
		backface-visibility: hidden;

		@media (min-width: $breakpoint-desktop) {
			$size: 500px;

			display: block;
			width: $size;
			height: 100%;
			position: absolute;
			right: $size * -1;
			top: 0;
			overflow: hidden;
			z-index: 3;
			transform: translateX(-5px);

			> div {
			    position: absolute;
			    width: 100%;
			    height: 200%;
			    left: ($size * -1) + 10px;
			    top: 50%;
			    background-color: $dark;
			    transform: translateY(-50%) rotate(7deg);
			    transform-origin: 100% 75%;
			    will-change: transform;
			    transition: transform 0.1s, opacity 0.2s;

				/*&.color-light {
					background-color: $light;
				}*/
			}
		}
	}

	&__content-slide {
		background-color: $darker;
		width: 100%;
		height: 100%;
		padding: 60px 20px 30px 20px;
		padding-left: 20px !important;

		@media (min-width: $breakpoint-desktop) {
			width: 70vw;
			height: 100%;
			padding-left: 20vw !important;
			padding-right: 10vw;
		}
		
		#{$block}__project + & {
			padding-left: 0;
			width: 100%;

			@media (min-width: $breakpoint-desktop) {
				padding-left: 30vw !important;
				width: 72vw;
			}
		}

		.contact-table {
			margin-bottom: auto;
		}
	}

	&__content {
		&-title {
			font-size: 16px;
			letter-spacing: 0.8px;
			line-height: 19px;
			text-transform: uppercase;
			color: $light;
			margin-bottom: 20px;

			@media (min-width: $breakpoint-desktop) {
				font-size: 15px;
				letter-spacing: 0.75px;
				line-height: 18px;
				margin-bottom: 30px;
			}

			&--hidden-desktop {
				@media (min-width: $breakpoint-desktop) {
					display: none;
				}
			}
		}

		&-quote {
			font-family: $extra-font;
			font-size: 26px;
			letter-spacing: 1.06px;
			line-height: 32px;
			color: $light;
			margin-bottom: 55px;

			@media (min-width: $breakpoint-desktop) {
				font-size: 42px;
				letter-spacing: 1.71px;
				line-height: 44px;
				margin-bottom: 65px;
			}
		}
	}

	&__intent {
		position: absolute;
		bottom: 60px;
		right: -37vw;
		height: 26px;
		width: 150px;
		display: none;
		overflow: hidden;
		transition: opacity 0.5s;

		@media (min-width: $breakpoint-desktop) {
			display: block;
		}

		> div {
			position: absolute;
			white-space: nowrap;
			top: 3px;
			height: 20px;
			line-height: 20px;
			font-size: 13px;
			letter-spacing: 0.4px;
			color: $light;
			text-transform: uppercase;
			animation: scrolltext 2s 0s linear infinite;


			@keyframes scrolltext {
				0% {
					transform: translateX(0);
				}
				100% {
					transform: translateX(-63.2725px);
				}
			}
		}

		&.hidden {
			opacity: 0;
			pointer-events: none;
		}
	}

	&__layer {
		background-color: $lighter;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: opacity 0.3s;
		overflow: hidden;

		&--inactive {
			opacity: 0;
			pointer-events: none;
		}

		&--do-not-transition {
			transition: opacity 0s;
		}

		> .project-detail {
			width: 100%;
			height: 100%;
			overflow: auto;
		}
	}

	&__centered-content {
		position: relative;
		z-index: 50;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		height: 100%;

		&--spaced {
			@media (min-width: $breakpoint-desktop) {
				justify-content: space-between;
				padding-top: 10vh;
			}
		}
	}

	&--play-animations {
		#{$block} {
			&__intro {
				@media (min-width: $breakpoint-desktop) {
					width: 60vw;
				}
			}
		}
	}

	&--skip-animations {
		#{$block} {
			&__intro-animation {
				&:after {
					content: " ";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: $dark;
					opacity: 1;
					z-index: 1;
					animation: removelayer 0.4s 2.7s ease-in-out forwards;

					@keyframes removelayer {
						0% {
							opacity: 1;
						}

						100% {
							opacity: 0;
						}
					}
				}
			}

			&__intro {
				@media (min-width: $breakpoint-desktop) {
					transition: width 0s 0s !important;
					width: 60vw;
				}
			}
		}

		#{$block} {
			&__logo {
				animation: none !important;
				transition: 0s 0s !important;
				transform: translate(-121px, 0) !important;

				@media (min-width: $breakpoint-tablet) {
					animation: none !important;
					transition: 0s 0s !important;
					transform: translate(-243px, 0) !important;
				}

				svg {
					animation: none !important;
					transition: 0s 0s !important;
				}

				&-svg {
					transition: 0s 0s;
					animation: none;
					transform: none;

					#left-1,
					#left-2,
					#left-3,
					#left-4,
					#right-1,
					#right-2,
					#right-3,
					#right-4 {
						transition: 0s 0s;
						animation: none;
						transform: none;
						fill: $light;
					}
				}
			}

			&__text-logo,
			&__slogan,
			&__intro {
				transition: 0s 0s;
				animation: 0;
			}
		}
	}

	&--locked {
		#{$block} {
			&__project,
			&__project-container {
				background-color: $dark;
			}
		}
	}

	&--locked-front {
		#{$block} {
			&__triangle {
				> div {
					transform: translateY(-50%) rotate(0deg) !important;
					transition: opacity 0.3s, transform 0.3s;
					opacity: 0;
				}
			}
		}
	}
}