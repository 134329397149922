.social-container {
	width: 100%;

	@media (min-width: $breakpoint-desktop) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 35px;
	}

	&__media {
		margin-bottom: 50px;
		margin-top: 40px;

		@media (min-width: $breakpoint-desktop) {
			margin: 30px 0;
		}

		ul {
			list-style: none;

			li {
				margin-bottom: 15px;

				&:last-child {
					@media (min-width: $breakpoint-desktop) {
						margin-bottom: 0;
					}
				}

				a {
					color: $light;
					text-decoration: none;
					display: flex;
					align-items: center;
					outline: none;
					box-shadow: none;

					img {
						display: inline-block;
						margin-right: 12px;
					}
				}
			}
		}
	}

	&__logo {
		margin-bottom: 25px;

		@media (min-width: $breakpoint-desktop) {
			margin-bottom: 0;
		}

		img {
			width: 124px;
			height: 62px;
			display: block;

			@media (min-width: $breakpoint-desktop) {
				width: 160px;
				height: 80px;
			}
		}
	}
}