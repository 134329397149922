.clickable-plus {
	width: 28px;
	height: 28px;
	border-radius: 100%;
	background-color: $light;
	pointer-events: none;
	z-index: 110;
	transition: 0.3s;

	img {
		width: 14px;
		height: 14px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&--right-bottom {
		position: absolute;
		bottom: 15px;
		right: 15px;
	}

	@media (min-width: $breakpoint-desktop) {
		opacity: 0;
	}
}