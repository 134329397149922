.navigation {
	$block: &;

	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 350;
	background-color: $darker;
	transform: translateX(-100%);
	opacity: 0;
	transition: opacity 0.3s, transform 0s 0.3s;
	padding: 80px 30px 60px 30px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: auto;

	@media (min-width: $breakpoint-tablet) {
		padding: 60px 70px 40px 70px;
		
	}

	&--active {
		transform: translateX(0);
		transition: opacity 0.3s, transform 0s 0s;
		opacity: 1;
	}

	&--disabled {
		#{$block} {
			&__layer {
				display: block;
			}
		}
	}

	&__layer {
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 451;
		cursor: wait;
		display: none;
		position: absolute;
	}

	&__list {
		z-index: 1;
		position: relative;
		list-style: none;
		margin-bottom: 10px;
		overflow: hidden;

		.active-menu & {
			overflow: visible;
		}

		@media (min-width: $breakpoint-tablet) {
			margin-bottom: 50px;
		}

		&-container {
			overflow: hidden;
			position: relative;

			&:after {
				@media (min-width: $breakpoint-tablet) {
					content: " ";
					position: absolute;
					top: 0;
					background-color: rgba($light, 0.5);
					height: 1px;
					display: block;
					z-index: 1;
					width: calc(100% - 140px);
					left: 70px;
				}
			}

			@media (min-width: $breakpoint-tablet) {
				padding-left: 70px;
				padding-right: 70px;
				margin: 0 -70px;
			}
		}

		li {
			background-color: $darker;
			position: relative;
			border-bottom: 1px solid rgba($light, 0.5);
			width: 100%;

			@media (min-width: $breakpoint-tablet) {
				> a {
					&:hover,
					&:active {
						transform: translateX(-6000px);
						transition: transform 60s linear;
						&:before {
							content: attr(data-label) attr(data-label);
							position: absolute;
							white-space: nowrap;
							right: calc(100%);
							padding-left: 20px;
						}

						&:after {
							content: attr(data-label) attr(data-label) attr(data-label) attr(data-label) attr(data-label) attr(data-label) attr(data-label) attr(data-label) attr(data-label) attr(data-label) attr(data-label) attr(data-label) attr(data-label) attr(data-label) attr(data-label) attr(data-label) attr(data-label) attr(data-label) attr(data-label) attr(data-label) attr(data-label) attr(data-label)attr(data-label) attr(data-label) attr(data-label) attr(data-label) attr(data-label) attr(data-label) attr(data-label) attr(data-label);
							white-space: nowrap;
							left: calc(100%);
							padding-left: 20px;
						}

						&:before,
						&:after {
							color: $light;
							text-transform: uppercase;
							display: block;
							text-decoration: none;
							font-size: 27px;
							letter-spacing: 0.92px;
							line-height: 33px;
							outline: none;
							box-shadow: none;
							position: absolute;
							top: 20px;

							@media (min-width: $breakpoint-tablet) {
								font-size: 86px;
								letter-spacing: 4.3px;
								line-height: 105px;
							}

							@media (min-width: $breakpoint-tablet) and (max-height: 900px) {
								line-height: 80px !important;
								font-size: 70px !important;
							}
						}
					}
				}
			}

			a {
				color: $light;
				text-transform: uppercase;
				padding: 20px 0;
				width: 100%;
				display: block;
				text-decoration: none;
				font-size: 27px;
				letter-spacing: 0.92px;
				line-height: 33px;
				outline: none;
				box-shadow: none;

				@media (min-width: $breakpoint-tablet) {
					font-size: 86px;
					letter-spacing: 4.3px;
					line-height: 105px;
					display: inline-block;
					width: auto;
					position: relative;
				}

				@media (min-width: $breakpoint-tablet) and (max-height: 900px) {
					line-height: 80px !important;
					font-size: 70px !important;
				}
			}

			&:nth-child(1) {
				transform: translateY(-100%);
				transition: transform 1.2s 0s;
				position: relative;
				z-index: 9;
			}

			&:nth-child(2) {
				transform: translateY(-200%);
				transition: transform 1.2s 0.01s;
				position: relative;
				z-index: 8;
			}

			&:nth-child(3) {
				transform: translateY(-300%);
				transition: transform 1.2s 0.02s;
				position: relative;
				z-index: 7;
			}

			&:nth-child(4) {
				transform: translateY(-400%);
				transition: transform 1.2s 0.03s;
				position: relative;
				z-index: 6;
			}

			&:nth-child(5) {
				transform: translateY(-500%);
				transition: transform 1.2s 0.04s;
				position: relative;
				z-index: 5;
			}

			&:nth-child(6) {
				transform: translateY(-600%);
				transition: transform 0.3s 0.05s;
				position: relative;
				z-index: 4;
			}
		}

		.active-menu & {
			li {
				transform: translateY(0);
			}
		}

		&--no-animation {
			overflow: visible;

			li {
				transform: translateY(0) !important;
				transition: none !important;
				background-color: rgba(0, 0, 0, 0);
				opacity: 1;
				border-bottom: 1px solid rgba($dark, 0.5);

				@media (min-width: $breakpoint-tablet) {
					padding: 15px 0;
				}

				a {
					color: $dark;

					@media (min-width: $breakpoint-tablet) {
						font-size: 64px;
						letter-spacing: 3.2px;
						line-height: 79px;
					}

					&:before,
					&:after {
						color: $dark !important;
						

						@media (min-width: $breakpoint-tablet) {
							font-size: 64px !important;
							letter-spacing: 3.2px !important;
							line-height: 79px !important;
							top: 20px !important;
						}
					}
				}
			}
		}
	}

	&__extra-list {
		margin-top: 40px;
	}

	.social-container {
		margin-bottom: 0;
		transform: translateY(-50px);
		opacity: 0;
		pointer-events: none;
		transition: transform 1s 0.8s, opacity 1s 0.8s;

		&__logo {
			margin-bottom: 0;
			margin-top: auto;
		}

		&__media {
			@media (min-width: $breakpoint-tablet) {
				margin-bottom: 0;
			}
		}

		.active-menu & {
			transform: translateY(0);
			opacity: 1;
			pointer-events: auto;
		}
	}
}