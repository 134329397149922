.about {
	&__slogan {
		margin: 5px 0;

		@media (min-width: $breakpoint-desktop) {
			margin:  30px 0;
		}

		img {
			&:nth-child(1) {
				display: none;

				@media (min-width: $breakpoint-wide) {
					display: block;
					width: 985px;
					height: 104px;
				}
			}

			&:nth-child(2) {
				display: block;
				width: 315px;
				height: 108px;

				@media (min-width: $breakpoint-wide) {
					display: none;
				}
			}
		}
	}

	&__header {
		display: flex;
		width: 230%;

		@media (min-width: $breakpoint-wide) {
			width: 165%;
		}

		&-image {
			background-color: $dark;
			display: block;
			width: 40%;
			padding-bottom: 34%;
			height: 0;
			margin-right: 30px;
			background-size: cover;
			background-position: center center;

			@media (min-width: $breakpoint-wide) {
				margin-right: 200px;
				padding-bottom: 28%;
			}

			&:last-child {
				margin-right: 0;

				@media (min-width: $breakpoint-wide) {
					margin-right: 0;
				}
			}
		}

		&-container {
			width: 100%;
			height: 100%;
			position: relative;
			margin: 35px 15px;

			@media (min-width: $breakpoint-wide) {
				margin: 100px 15px;
			}
		}
	}

	&__intro {
		margin: 50px 0 30px 0;
		padding: 0 15px;

		@media (min-width: $breakpoint-desktop) {
			margin: 120px 0 60px 0;
		}

		&-main {
			margin-bottom: 40px;
			font-size: 16px;
			letter-spacing: 0.8px;
			line-height: 28px;

			@media (min-width: $breakpoint-desktop) {
				font-size: 24px;
				letter-spacing: 1.2px;
				line-height: 38px;
				margin-bottom: 80px;
			}
		}

		&-left,
		&-right {
			font-size: 12px;
			letter-spacing: 0.6px;
			line-height: 22px;
		}

		&-left {
			margin-bottom: 40px;

			@media (min-width: $breakpoint-desktop) {
				font-size: 20px;
				letter-spacing: 1px;
				line-height: 32px;
			}
		}
	
		&-right {
			@media (min-width: $breakpoint-desktop) {
				font-size: 16px;
				letter-spacing: 0.8px;
				line-height: 26px;
			}
		}
	}

	&__logos {
		margin: 20px 0;

		@media (min-width: $breakpoint-desktop) {
			margin: 60px 0;
		}

		&-slider {
			.flickity-cell {
				width: 33.33%;
				min-height: 100px;
				display: flex;
				align-items: center;
				justify-content: center;

				@media (min-width: $breakpoint-desktop) {
					width: 20%;
				}

				img {
					max-width: 75%;
					max-height: 30px;

					@media (min-width: $breakpoint-desktop) {
						max-height: 40px;
						max-width: 140px;
					}
				}
			}
		}
	}

	&__main {
		border-bottom: 50px;

		@media (min-width: $breakpoint-desktop) {
			border-bottom: 100px;
		}
	}
}