body,
html {
	background-color: $lighter;
	position: relative;
	font-family: $base-font;
	scroll-behavior: smooth;

	@media (min-width: $breakpoint-desktop) {
		width: 100%;
		height: 100%;
		cursor: none !important;
	}
}

* {
	@media (min-width: $breakpoint-desktop) {
		cursor: none !important;

		&:hover,
		&:focus,
		&:active {
			cursor: none !important;
		}
	}
}

:root {
	--cursor-scale: 1;
	--cursor-width: 15px;
	--cursor-height: 15px;
}

html {
	overflow-y: overlay;
}

body {
	transition: background-color 0s;

	&.color-light {
		/*background-color: $lighter;*/
	}

	&.light-bg {
		&.is-home.intro-animation-active,
		&:not(.is-home) {
			.home-scroll {
				&__text-logo {
					&--dark {
						opacity: 1;
					}
				}
			}
		}
	}

	&.dark-bg {
		&.is-home.intro-animation-active,
		&:not(.is-home) {
			.home-scroll {
				&__text-logo {
					&--light {
						opacity: 1;
					}
				}
			}
		}
	}
}

.active-menu  {
	.home-scroll {
		&__text-logo {
			&--dark {
				opacity: 0 !important;
			}

			&--light {
				opacity: 1;
			}
		}
	}
}

.mvv {
	@media (min-width: $breakpoint-desktop) {
		width: 100%;
		height: 100%;
	}
}

#root {
	@media (min-width: $breakpoint-desktop) {
		width: 100%;
		height: 100%;
	}
}

* {
	box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $base-font;
}

p,
a,
li,
strong,
button {
	font-family: $base-font;
}

// -webkit-backdrop-filter: sepia(1) grayscale(0) invert(1) contrast(3) saturate(3.1) hue-rotate(-156deg) brightness(0.9);
// backdrop-filter: sepia(1) grayscale(0) invert(1) contrast(3) saturate(3.1) hue-rotate(-156deg) brightness(0.9);


.custom-cursor-dot {
    border-radius: 20px;
    height: var(--cursor-height);
    left: 50%;
    left: 0;
    mix-blend-mode: difference;
    pointer-events: none;
    position: fixed;
    top: 50%;
    top: 0;
    transform: translate(calc(var(--mouse-x) - 50%),calc(var(--mouse-y) - 50%)) scale(var(--cursor-scale));
    transition: width 0.3s 0s ease-out, height 0.3s 0s ease-out, transform 0s 0s;
    width: var(--cursor-width);
    will-change: width,height,transform;
    z-index: 999;
    backdrop-filter: sepia(1) contrast(0.8) hue-rotate(-10deg);
    background: darken($light, 5%);
    perspective:1px;
    opacity: 1;
    backface-visibility: none;
    display: none;

	@media (min-width: $breakpoint-desktop) {
		display: block;
	}

	&.plus-icon,
	&.arrow-icon {
		&:after {
			content: " ";
			position:  absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 20px;
			height: 20px;
			display: block;
			background-image: url('../img/plus.svg');
			background-position: center center;
			background-size: contain;
			background-repeat: no-repeat;
		}
	}

	&.arrow-icon {
		&:after {
			background-image: url('../img/arrow_dark.svg');
		}
	}
}