.project-detail {
/*	[class*='row'],
	[class*='container'] {
		padding-left: 0;
		padding-right: 0;
		margin-left: 0;
		margin-right: 0;
	}

	[class*='col'] {
		padding-left: 0;
		padding-right: 0;
	}*/

	padding: 0 20px;
	overflow: hidden;

	@media (min-width: $breakpoint-desktop) {
		padding: 0 65px;
	}

	&__header {
		width: 100%;
		height: 90vh;

		@media (min-width: $breakpoint-desktop) {
			height: 100vh;
		}

		.container,
		.container-fluid {
			height: 100%;

			> .row {
				/*width: 100%;*/

				&.align-items-center {
					align-items: flex-start !important;

					@media (min-width: $breakpoint-tablet) {
						align-items: center !important;
					}
				}
			}
		}

		.row {
			height: 100%;
		}
	}

	&__photo {
		width: calc(100% + 70px);
		height: 60vh;
		background-color: $dark;
		background-size: cover;
		background-position: center center;
		margin: 0 -35px 0 -35px;

		@media (min-width: $breakpoint-desktop) {
			height: 70vh;
			margin: 0;
			width: 100%;
			/*cursor: url(../img/cursor.png), auto;
			cursor: url(../img/cursor.svg) 15 15, move;
*/		}

		.clickable-plus {
			right: -20px;
		}
	}

	&__second {
		opacity: 0;
		transform: 0;
		will-change: transform;
		transition: opacity 0.5s, transform 0.5s;
		margin-top: 3vh;
		margin-bottom: -2vh;

		@media (min-width: $breakpoint-desktop) {
			margin-bottom: -15vh;
		}

		&--active {
			opacity: 1;
			transform: translateY(-5vh);
			
			@media (min-width: $breakpoint-desktop) {
				transform: translateY(-25vh);
			}
		}
	}

	&__second-photo {
		width: 100%;
		height: 70vh;
		background-color: $dark;
		background-size: cover;
		background-position: center center;
		margin-bottom: 25px;

		@media (min-width: $breakpoint-desktop) {
			height: 90vh;
			/*cursor: url(../img/cursor.png), auto;
			cursor: url(../img/cursor.svg) 15 15, move;*/
			margin-bottom: 0;
		}

		&--small {
			height: 45vh;
			margin-bottom: 25px;

			@media (min-width: $breakpoint-desktop) {
				width: 80%;
				height: 60vh;
				margin-bottom: 0;
			}
		}
	}

	&__title {
		@extend .home-scroll__project-title;

		text-align: left;
		color: $dark;

		@media (min-width: $breakpoint-desktop) {
			text-align: right;
		}
	}

	&__tagline {
		@extend .home-scroll__project-tagline;

		text-align: left;
		color: $dark;

		@media (min-width: $breakpoint-desktop) {
			text-align: right;
		}
	}

	&__dummy-cta {
		@extend .home-scroll__project-cta;

		opacity: 0;
		pointer-events: none;
	}

	&__full-boxed {
		width: 100%;
		margin-bottom: 25px;

		@media (min-width: $breakpoint-desktop) {
			margin-bottom: 60px;
		}

		img {
			width: 100%;

			@media (min-width: $breakpoint-desktop) {
				/*cursor: url(../img/cursor.png), auto;
				cursor: url(../img/cursor.svg) 15 15, move;*/
			}
		}
	}

	&__semi {
		&-image {
			width: 100%;
			
			@media (min-width: $breakpoint-desktop) {
				/*cursor: url(../img/cursor.png), auto;
				cursor: url(../img/cursor.svg) 15 15, move;*/
			}

			&--small {
				margin-left:  -40px;
				margin-right:  -40px;
				width:  calc(100% + 80px);
				min-width: calc(100% + 80px);

				@media (min-width: $breakpoint-desktop) {
					width: 90%;
					margin-left: 10%;
					margin-right: 0;
				}
			}
		}

		figure {
			margin-bottom: 25px;

			@media (min-width: $breakpoint-desktop) {
				margin-bottom: 60px;
			}
		}
	}

	&__quote {
		&-text {
			padding-right: 10%;
			margin: 50px 0;
			font-size: 28px;
			line-height: 36px;
			letter-spacing: 0.67px;
			margin-bottom: 80px;
			display: block;
			font-family: $extra-font;
			color: $dark;

			@media (min-width: $breakpoint-desktop) {
				font-size: 42px;
				letter-spacing: 1px;
				line-height: 44px;
				padding-right: 15%;
			}

			@media (min-width: $breakpoint-wide) {
				padding-right: 34%;
			}
		}

		&-author {
			display: block;
			font-size: 13px;
			line-height: 16px;
			letter-spacing: 0.65px;
			margin-top: 30px;
			text-transform: uppercase;
			font-family: $base-font;

			@media (min-width: $breakpoint-desktop) {
				font-size: 15px;
				letter-spacing: 0.75px;
				line-height: 18px;
			}
		}

		figure {
			margin-bottom: 25px;

			@media (min-width: $breakpoint-desktop) {
				margin-bottom: 60px;
			}
		}

		&-image {
			width: 100%;
			
			@media (min-width: $breakpoint-desktop) {
				/*cursor: url(../img/cursor.png), auto;
				cursor: url(../img/cursor.svg) 15 15, move;*/
				width: 90%;
				margin-left: 10%;
			}
		}

		&--about {
			img {
				width: 100%;
				margin: 0;
				/*cursor: auto;*/
			}

			blockquote {
				margin: 10px 0 30px 0;

				@media (min-width: $breakpoint-desktop) {
					padding-right: 10%;
					margin: 0;
				}

				@media (min-width: $breakpoint-wide) {
					padding-right: 20%;
				}
			}

			.clickable-plus {
				display: none;
			}
		}

		&--mirrored {
			img {
				width: 100%;
				margin: 0;
				/*cursor: auto;*/
			}

			blockquote {
				margin: 10px 0 30px 0;

				@media (min-width: $breakpoint-desktop) {
					padding-right: 10%;
					margin: 0;
				}

				@media (min-width: $breakpoint-wide) {
					padding-right: 20%;
				}
			}
		}
	}

	&__full {
		width: calc(100% + 40px);
		margin: 0 -20px;
		margin-bottom: 25px;
		
		@media (min-width: $breakpoint-desktop) {
			margin: 0 -65px;
			width: calc(100% + 130px);
			margin-bottom: 60px;
		}

		img {
			width: 100%;
			
			/*@media (min-width: $breakpoint-desktop) {
				cursor: url(../img/cursor.png), auto;
				cursor: url(../img/cursor.svg) 15 15, move;
			}*/
		}
	}

	&__copyright {
		display: flex;
		font-size: 11px;
		letter-spacing: 0.5px;
		line-height: 12px;
		margin: 30px 0 20px 0;
		justify-content: center;
		text-transform: uppercase;

		@media (min-width: $breakpoint-desktop) {
			font-size: 13px;
			transform: translateY(110px);
		}
	}

	&__footer {
		margin: 0 15px;
		margin-top: 50px;
		border-top: 1px solid rgba($dark, 0.5);
		width: calc(100% - 40px);
		padding-bottom: 20px;

		@media (min-width: $breakpoint-desktop) {
			margin-top: 100px;
		}

		&-logo {
			margin: 30px 0 20px 0;
			width: 100%;
			display: flex;
			justify-content: space-between;

			img {
				max-height: 40px;

				@media (min-width: $breakpoint-desktop) {
					max-height: 140px;
				}
			}

			&--padding {
				margin: 30px 0 30px 0;

				@media (min-width: $breakpoint-desktop) {
					margin: 30px 0 40px 0;
				}
			}
		}
	}

	.clickable-plus {
		display: none;

		@media (min-width: $breakpoint-tablet) {
			display: block;
		}
	}

	&__carousel {
		width: 100%;
		height: 100%;
		background-color: $light;

		&-container {
			position:  fixed;
			width:  100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 200;
			transform: translateX(100%);
			opacity: 0;
			transition: opacity 0.3s 0s, transform 0s 0.3s;

			&--active {
				transform: translateX(0);
				opacity: 1;
				transition: opacity 0.3s, transform 0s 0s !important;
				z-index: 500;
			}
		}

		&-count {
			position: absolute;
			bottom: 30px;
			left: 20px;
			font-size: 15px;
			letter-spacing: 0.75px;
			line-height: 18px;
			font-family: $base-font;
			color: $dark;
		}

		&-close {
			background-color: $dark;
			width: 40px;
			height: 40px;
			border-radius: 100%;
			box-shadow: none;
			outline: none;
			border: none;
			cursor: pointer;
			margin-top: -14px;
			margin-left: auto;
			display: table;
			position: relative;

			img {
				position: absolute;
				width: 14px;
				height: 14px;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			&-container {
				padding: 0 10px;
    			margin: 50px 0 0 0;
    			position: absolute;
    			top: 0;
    			left: 0;
    			width: 100%;
    			justify-content: flex-end;
    			z-index: 500;
			}
		}

		.flickity-button {
			background-color: rgba(0, 0, 0, 0);
			width: 50%;
			height: 100%;
			outline: none;
			border: none;
			box-shadow: none;
			padding: 0;

			&.previous {
				margin-left: 0;
				left: 0;

				svg {
					left: 25px;

					path {
						transform: scale(0.5);
	    				transform-origin: 40% center;
					}
				}
			}

			&.next {
				margin-right: 0;
				right: 0;

				svg {
					right: 25px;
					left: auto;

					path {
						transform: scale(0.5) rotate(180deg);
	    				transform-origin: 53% center;
					}
				}
			}

			svg {
				width: 40px;
				height: 40px;
				background-color: $dark;
				border-radius: 100%;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);

				path {
					fill: $light;
				}
			}
		}
	}

	figure {
		> img {
			min-height: 150px;
			min-width: 100%;
			background-color: $dark;
		}
	}

	&__slide {
		background-color: $lighter;
		width: 100vw;
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			max-width: 90vw;
			max-height: 100vh;
		}
	}

	&__next {
		margin-top: 20px;
		margin-bottom: 45px;

		@media (min-width: $breakpoint-desktop) {
			margin-bottom: 55px;
		}

		&-title {
			display: block;
			text-transform: uppercase;
			font-size: 12px;
			letter-spacing: 0.6px;
			line-height: 15px;
			color: $dark;

			@media (min-width: $breakpoint-desktop) {
				font-size: 15px;
			}
		}

		&-countdown {
			display: none;

			@media (min-width: $breakpoint-desktop) {
				display: block;
				position: absolute;
				top: 25px;
				pointer-events: none;
				right: 0;
				width: 30%;
			}

			&-title {
				font-size: 42px;
				letter-spacing: 1px;
				line-height: 44px;
				color: $dark;
				font-family: $extra-font;
				text-transform: none;
				margin-top: 25px;
			}

			&-number {
				font-size: 15px;
				letter-spacing: 0.75px;
				line-height: 18px;
				color: $dark;
				font-family: $base-font;
				text-transform: none;
			}
		}

		&-button {
			width: 100%;
			display: block;
			outline: none;
			box-shadow: none;
			border: none;
			color: $dark;
			text-decoration: none;
			margin-top: 14px;
			font-family: $base-font;
			position: relative;
			text-transform: uppercase;

			@media (min-width: $breakpoint-desktop) {
				font-size: 100px;
				line-height: 122px;
				max-width: 100%;
				margin-top: -40px;
				padding-top: 50px;
				/*cursor: url(../img/cursor_arrow.png), auto;
				cursor: url(../img/cursor_arrow.svg) 15 15, move;*/
			}

			&:after {
				width: 32px;
				height: 32px;
				background-color: $dark;
				content: " ";
				border-radius: 100%;
				display: block;
				position: absolute;
				top: -2px;
				right: 0;
				background-image: url('../img/arrow.svg');
				background-size: 16px 12px;
				background-repeat: no-repeat;
				background-position: center center;
				transition: transform 0.3s;

				@media (min-width: $breakpoint-desktop) {
					width: 40px;
					height: 40px;
					top: 50%;
					transform: translateY(-50%);
					display: none;
				}
			}

			&:hover {
				&:after {
					transform: translateX(5px);

					@media (min-width: $breakpoint-desktop) {
						transform: translate(5px, -50%);
						display: none;
					}
				}
			}

			span {
				color: $dark;
				text-decoration: none;
				font-family: $base-font;
				font-size: 27px;
				letter-spacing: 0.92px;
				font-weight: normal;

				@media (min-width: $breakpoint-desktop) {
					font-size: 100px;
					line-height: 122px;
				}
			}
		}
	}
}
