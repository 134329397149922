@font-face {
	font-family: 'Roman';
	src:  url('../fonts/Ogg-Roman.woff2') format('woff2'),
	url('../fonts/Ogg-Roman.woff') format('woff');
}

@font-face {
	font-family: 'Everett';
	src:  url('../fonts/TWKEverett-Regular-web.woff2') format('woff2'),
	url('../fonts/TWKEverett-Regular-web.woff') format('woff');
}