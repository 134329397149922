.header {
	$block: &;

	position: fixed;
	z-index: 420;
	padding: 30px 5px 13px 5px;
	/*background-color: $dark;*/
	width: 100%;
	transition: background-color 0.3s 0s, border-color 0.3s 0s;

	@media (min-width: $breakpoint-tablet) {
		padding: 0 10px 0 65px;
		margin: 50px 0 0 0;
		background-color: rgba(0, 0, 0, 0);
		border-color: rgba(0, 0, 0, 0);
	}

	.light-bg & {
		/*background-color: $lighter;*/
		border-bottom: 1px solid rgba($dark, 0.5);
		transition: background-color 0.3s 0s, border-color 0.3s 0s;

		@media (min-width: $breakpoint-tablet) {
			background-color: rgba(0, 0, 0, 0);
			border-color: rgba(0, 0, 0, 0);
		}
	}

	.dark-bg &,
	.active-menu & {
		/*background-color: $dark;*/
		border-bottom: 1px solid rgba($light, 0.5);
		transition: background-color 0.3s 0s, border-color 0.3s 0s;

		@media (min-width: $breakpoint-tablet) {
			background-color: rgba(0, 0, 0, 0);
			border-color: rgba(0, 0, 0, 0);
		}
	}

	&__hamburger {
		width: 35px;
		height: 35px;
		border-radius: 100%;
		display: flex;
		margin-left: auto;
		padding: 10px 9px;
		flex-direction: column;
		justify-content: space-around;
		margin-top: -14px;
		transition: background-color 0.3s 0s, border-color 0.3s 0s, opacity 0.3s;
		opacity: 0;

		@media (min-width: $breakpoint-tablet) {
			width: 40px;
			height: 40px;
			padding: 10px 12px;
		}

		.light-bg & {
			background-color: $dark;

			&:hover {
				background-color: darken($dark, 5%);
				transition: background-color 0.3s 0s;
			}
		}

		.dark-bg &,
		.active-menu & {
			background-color: $light;

			&:hover {
				background-color: darken($light, 5%);
				transition: background-color 0.3s 0s;
			}
		}

		span {
			width: 17px;
			height: 2px;
			border-radius: 2px;
			display: block;
			transition: background-color 0.3s 0s, border-color 0.3s 0s, opacity 0.3s, transform 0.3s;
			transform-origin: 48% 30%;

			@media (min-width: $breakpoint-tablet) {
				transform-origin: 50% 40%;
			}

			.light-bg & {
				background-color: $lighter;
			}

			.dark-bg &,
			.active-menu & {
				background-color: $dark;
			}

			&:nth-child(2) {
				margin: 2px 0;
			}
		}

		&--active {
			span {
				&:nth-child(1) {
					transform: rotate(45deg) translate(4px, 3px);

					@media (min-width: $breakpoint-tablet) {
						transform: rotate(45deg) translate(5px, 5px);
					}
				}

				&:nth-child(2) {
					opacity: 0;
				}

				&:nth-child(3) {
					transform: rotate(-45deg) translate(4px, -4px);

					@media (min-width: $breakpoint-tablet) {
						transform: rotate(-45deg) translate(5px, -6px);
					}
				}
			}
		}
	}
}

body {
	&.is-home.intro-animation-active,
	&:not(.is-home) {
		.header {
			&__hamburger {
				opacity: 1;
			}
		}
	}
}