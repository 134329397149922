.button {
	background-color: rgba($light, 0.16);
	color: $light;
	font-size: 14px;
	letter-spacing: 0.75px;
	line-height: 18px;
	padding: 15px 40px;
	appearance: none;
	border: none;
	box-shadow: none;
	text-transform: uppercase;
	border-radius: 24px;
	transition: background-color 0.2s;
	cursor: pointer;
	outline: none;
	font-weight: normal;

	@media (min-width: $breakpoint-desktop) {
		background-color: rgba(#231710, 0.34);
	}

	&:hover,
	&:focus {
		background-color: rgba($light, 0.30);
		outline: none;

		@media (min-width: $breakpoint-desktop) {
			background-color: rgba(#231710, 0.48);
		}
	}

	&:active {
		background-color: rgba($light, 0.36);
		outline: none;

		@media (min-width: $breakpoint-desktop) {
			background-color: rgba(#231710, 0.54);
		}
	}

	&--light-desktop {
		@media (min-width: $breakpoint-desktop) {
			background-color: rgba($light, 0.16);
		}
	}

	&--wide {
		padding-left: 50px;
		padding-right: 50px;
	}
}