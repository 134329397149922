$dark:						#4C4542; // Brown
$darker:					#312C2A; // Black-brown
$light:						#F2D4B6; // Beige
$lighter:					#FBF1E6; // Beige

$breakpoint-tablet:			767px;
$breakpoint-desktop:		992px;
$breakpoint-wide:			1200px;

$extra-font:				'Roman';
$base-font:					'Everett';
