.page-detail {
	$block: &;

	transition: opacity 0.3s;
	min-height: 100%;
	overflow: hidden;

	&--padding {
		padding: 0 5px;

		@media (min-width: $breakpoint-desktop) {
			padding: 0 65px;
		}
	}

	&--hidden {
		opacity: 0;
	}

	&--show {
		opacity: 1;
	}

	&__title {
		margin: 0 15px;
		width: calc(100% - 30px);
		border-top: 1px solid rgba($dark, 0.5);
		margin-top: 100px;
		padding: 14px 0;
		text-transform: uppercase;
		margin-bottom: 0;
		border-bottom: 0;

		@media (min-width: $breakpoint-desktop) {
			padding: 25px 0;
			margin-bottom: 0;
			margin-left: 15px;
			margin-right: 15px;
		}

		&--border-bottom {
			border-bottom: 1px solid rgba($dark, 0.5);
		}

		&--about {
			margin-left: 15px;
			margin-right: 15px;
			width: calc(100% - 30px);
		}

		h1 {
			font-size: 27px;
			letter-spacing: 0.92px;
			line-height: 33px;
			color: $dark;
			text-transform: uppercase;

			@media (min-width: $breakpoint-desktop) {
				font-size: 86px;
				letter-spacing: 4.3px;
				line-height: 105px;
			}
		}
	}

	&__jobs {
		border-top: 1px solid rgba($dark, 0.5);
		margin: 0px 15px 30px 15px;
		width: calc(100% - 30px);

		@media (min-width: $breakpoint-desktop) {
			margin-top: 0;
			margin-bottom: 0px;
			padding: 50px 0;
		}
	}

	&__job {
		padding: 30px 0 20px 0;
		border-bottom: 1px solid rgba($dark, 0.5);

		&:last-child {
			border-bottom: none;
		}

		@media (min-width: $breakpoint-desktop) {
			border-left: 1px solid rgba($dark, 0.5);
			border-bottom: none;
			padding-left: 40px;
			padding-top: 0;
			padding-bottom: 0;
			margin: 30px 0;
			margin-bottom: 100px !important;
		}

		&-title {
			margin-top: 0;
			font-size: 28px;
			line-height: 36px;
			letter-spacing: 0.67px;
			color: $dark;
			transition: transform 0.05s linear, color 1s;
			font-family: $extra-font;
			margin-bottom: 20px;
			text-transform: none;

			@media (min-width: $breakpoint-desktop) {
				font-family: $extra-font;
				font-size:  60px !important;
				letter-spacing: 2.44px !important;
				line-height: 72px !important;
				margin-bottom: 20px !important;
			}
		}

		&-row {
			// margin-top: 40px;
			
			// @media (min-width: $breakpoint-desktop) {
			// 	margin-top: 40px;
			// 	margin-bottom: 40px;
			// }

			// [class^="col-"] {
			// 	margin-bottom: 40px;

			// 	@media (min-width: $breakpoint-desktop) {
			// 		margin-bottom: 0;
			// 	}
			// }
		}

		&-button {
			margin-top: 20px;
			display: inline-block;
			text-decoration: none;;
			color: #F9F6F1;
			background-color: rgba(#231710, 34%);

			&:hover,
			&:focus {
				background-color: #231710;
				color: #F9F6F1;
			}
		}

		&-list {
			@media (min-width: $breakpoint-desktop) {
				margin-bottom: 20px;
			}

			li {
				position: relative;
				font-size: 16px;
				letter-spacing: 0.8px;
				line-height: 26px;
				color: $dark;
				transition: transform 0.05s linear, color 1s;
				font-family: $base-font;
				margin-bottom: 7px;

				&:before {
					content: " ";
					display: inline-block;
					margin-right: 10px;
					width: 1px;
					height: 1px;
				}

				&:after {
					content: " ";
					display: block;
					position: absolute;
					top: 22px;
					left: 0px;
					width: 3px;
					height: 3px;
					background-color: $dark;
					border-radius: 100%;
					margin-top: -10px;
				}
			}
		}

		&-intro {
			font-size: 18px;
			letter-spacing: 0.9px;
			line-height: 30px;
			color: $dark;
			transition: transform 0.05s linear, color 1s;
			font-family: $base-font;
			margin-bottom: 0;
		}

		&-subtitle {
			font-size: 20px;
			letter-spacing: 0px;
			line-height: 32px;
			color: $dark;
			transition: transform 0.05s linear, color 1s;
			font-family: $base-font;
			margin-bottom: 20px;
			margin-top: 40px;

			@media (min-width: $breakpoint-desktop) {
				// margin-top: 0;
			}
		}

		&-text {
			font-size: 16px;
			letter-spacing: 0.8px;
			line-height: 26px;
			color: $dark;
			transition: transform 0.05s linear, color 1s;
			font-family: $base-font;

			@media (min-width: $breakpoint-desktop) {
				margin-bottom: 20px;
			}
		}
	}

	&__archive {
		padding: 20px 0 40px 0;
		border-bottom: 1px solid rgba($dark, 0.5);
		margin: 0 15px;

		&-title {
			margin-left: 0;
			margin-right: 15px;
			font-size: 13px;
			text-transform: uppercase;
			letter-spacing: 0.65px;
			line-height: 16px;
			color: $dark;
			margin-bottom: 20px;

			@media (min-width: $breakpoint-desktop) {
				font-size: 15px;
				line-height: 18px;
				letter-spacing: 0.75px;
			}
		}

		&:first-child {
			border-top: 1px solid rgba($dark, 0.5);
		}

		&:last-child {
			border-bottom: none;
		}

		&--active {
			#{$block} {
				&__slider {
					//  -webkit-filter: grayscale(0%);
					// filter: grayscale(0%);
				}
			}
		}
	}

	&__slider {
		width: 100%;
		transition: filter 0.2s;

		img {
			height: 150px;
			margin-right: 35px;

			@media (min-width: $breakpoint-desktop) {
				height: 300px;
			}
		}

		.flickity-viewport {
			overflow: visible;
		}

		.flickity-button {
			background-color: $dark;
			width: 40px;
			height: 40px;
			outline: none;
			border: none;
			box-shadow: none;
			display: none;

			@media (min-width: $breakpoint-desktop) {
				display: block;
			}

			&.previous {
				margin-left: 0;
			}

			&.next {
				margin-right: 0;
			}

			svg {
				path {
					fill: $light;
				}
			}
		}

		&--archive {
			//  -webkit-filter: grayscale(100%);
			// filter: grayscale(100%);
			
			img {
				height: auto;
				width: calc(50% - 17px);
			}
		}
	}

	.project-detail {
		&__footer {
			margin-top: 0;
		}
	}

	&--grey {
		background-color: #F0F0EF;
	}

	&__job-menu {
		position: sticky;
		top: 0;
		margin-top: 30px;
		display: none;

		@media (min-width: $breakpoint-desktop) {
			display: block;
		}

		li {
			a {
				position: relative;
				display: block;
				font-size: 16px;
				letter-spacing: 0.8px;
				line-height: 26px;
				color: $dark;
				transition: transform 0.05s linear, color 1s;
				font-family: $base-font;
				margin-bottom: 7px;
				text-decoration: none;

				&:before {
					content: " ";
					display: inline-block;
					margin-right: 10px;
					width: 1px;
					height: 1px;
				}

				&:after {
					content: " ";
					display: block;
					position: absolute;
					top: 22px;
					left: 0px;
					width: 3px;
					height: 3px;
					background-color: $dark;
					border-radius: 100%;
					margin-top: -10px;
				}
			}
		}
	}
}