.copyright {
	margin-top: 30px;
	display: block;
	width: 100%;

	&__list {
		list-style: none;

		@media (min-width: $breakpoint-desktop) {
			display: flex;
			justify-content: space-between;
			width: 100%;
		}
	}

	&__item {
		color: $light;
		text-transform: uppercase;
		font-size: 12px;
		letter-spacing: 0.6px;
		line-height: 15px;
		text-decoration: none;
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}

		a {
			color: $light;
			text-decoration: none;
		}
	}
}